import { DistanceMatrixService } from '@react-google-maps/api'
import { Drawer, Input, notification } from 'antd'
import { isEmpty } from '@aws-amplify/core'
import { useContext, useState, useMemo } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { AppContext } from './Layout'
import { CloseIcon } from '../svgIcons/closeIcon'
import { DetectLocationIcon } from '../svgIcons/detectLocationIcon'
import { handleDetectLocation } from '../utils/functions'
import { ImageWithBasePath } from './ImageWithBasePath'
import { LocationCard } from './LocationCard'
import { Media } from '../media'
import { NotDeliverable } from './NotDeliverable'
import { LocationProps, NotificationType } from '../ts/types/all.types'

export const SetLocation = ({
  open,
  setOpen,
  setLocation,
  showLocationPicker,
  setShowLocationPicker
}: LocationProps) => {
  const {
    theme,
    setLocationShow,
    profileData,
    setDistance,
    setMobileNotDeliverable,
    setSelectedAddress,
    setLocationHeaderClicked,
    locationHeaderClicked,
    tenant,
    deliveryPartnerPrice,
    tenantDeliveryPrice,
    location
  } = useContext(AppContext)
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  })
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api.destroy()
    api[type]({
      message,
      placement: 'top'
    })
  }

  const [placeId, setPlaceId] = useState('')
  const [distanceProps, setDistanceProps] = useState<any>({})

  const [address, setAddress] = useState<any>()

  const onClose = () => {
    setShowLocationPicker(false)
    setValue('')
    setOpen(false)
    clearSuggestions()
  }
  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        description,
        structured_formatting: { main_text, secondary_text }
      } = suggestion

      const onClick = () => {
        setAddress({ main_text, secondary_text })
        setValue('')
      }

      return (
        <LocationCard
          {...{
            setLocation,
            setOpen,
            setShowLocationPicker,
            place_id,
            main_text,
            secondary_text,
            clearSuggestions,
            setPlaceId,
            onClick,
            setDistanceProps,
            description
          }}
        />
      )
    })
  }

  const calculateDistance = useMemo(() => {
    return (
      <DistanceMatrixService
        options={{
          destinations: [
            {
              lat: Number(distanceProps?.lat || ''),
              lng: Number(distanceProps?.lng || '')
            }
          ],
          origins: [
            {
              lng: Number(tenant?.tenantAddresses?.longitude),
              lat: Number(tenant?.tenantAddresses?.latitude)
            }
          ],
          // @ts-ignore
          travelMode: 'DRIVING'
        }}
        callback={(response) => {
          if (!isEmpty(distanceProps)) {
            const distance =
              response?.rows[0]?.elements?.[0]?.distance?.text?.split(' ') ||
              response?.rows[0]?.elements?.[0]?.status?.split(' ')
            const distanceNumber = Number(distance?.[0]?.replace(',', ''))

            setDistance(distanceNumber)

            let distanceCovered = 0
            if (tenant?.deliveryType === 'Self_Delivery') {
              distanceCovered = tenantDeliveryPrice?.getTenantDeliveryPrice?.maximumDeliveryDistance
            } else {
              const deliveryPrice = deliveryPartnerPrice?.getDeliveryPartnerPrice?.find(
                (item: any) => item.countryCode === tenant.countryCode
              )
              distanceCovered = deliveryPrice?.maximumDeliveryDistance || 0
            }
            if (Number(distanceCovered) < distanceNumber || distance?.[0] === 'ZERO_RESULTS') {
              setMobileNotDeliverable(true)
            } else if (Number(distanceCovered) >= Number(distanceNumber)) {
              setMobileNotDeliverable(false)
              setLocation({
                main_text: address?.addressType || address?.main_text,
                secondary_text: address?.addressLine2 || address?.secondary_text
              })
              setSelectedAddress(address)
              setLocationHeaderClicked(false)
              setOpen(false)
            }
          }
        }}
      />
    )
  }, [distanceProps])

  const Address = profileData?.addresses || []

  return (
    <Media at="sm">
      {contextHolder}
      {calculateDistance}
      {!showLocationPicker && !location?.secondary_text && (
        <Drawer
          className="hideOnDesktop"
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={open}
          height="250"
          style={{ zIndex: 1 }}
        >
          <div className="mx-[24px] mt-[24px] text-center">
            <div
              style={{ color: theme?.customPrimary }}
              className="font-black text-2xl font-[500] leading-[27px]"
            >
              Set your location
            </div>
            <div
              className="border-box mt-[16px] h-[48px] border border-[1px] border-[#E5E5E5]"
              onClick={() => setShowLocationPicker(true)}
            >
              <div className="flex justify-between py-[11.5px] pl-[16px] pr-[21.23px]">
                <div className="pt-[2px] font-book text-md font-medium text-black-50">
                  Search Location
                </div>
                <ImageWithBasePath
                  src="DownArrow"
                  height={14}
                  width={14}
                  className="mt-[4px] h-[14px]"
                />
              </div>
            </div>
            <div
              style={{ color: theme?.customPrimary }}
              className="mt-[22.5px] font-black text-md font-[500] leading-[27px]"
              onClick={() => {
                setOpen(false)
                setLocationShow(false)
              }}
            >
              Skip & setup later
            </div>
          </div>
        </Drawer>
      )}
      {(locationHeaderClicked || (showLocationPicker && !location?.secondary_text)) && (
        <Drawer
          placement="bottom"
          className="hideOnDesktop"
          closable={false}
          onClose={onClose}
          open={open}
          height="600"
        >
          <NotDeliverable setOpen={setOpen} address={address} />
          <div className="mt-[24px] px-[24px] text-center">
            <div className="flex justify-between">
              <div></div>
              <div
                style={{ color: theme?.customPrimary }}
                className=" text-center font-black text-2xl font-[500] leading-[27px]"
              >
                Set your location
              </div>
              <div className="mt-[5px]" onClick={onClose} role="button" tabIndex={0}>
                <CloseIcon
                  viewBox="0 0 15 15"
                  width="14px"
                  height="14px"
                  strokeWidth="2"
                  fillColor={theme?.customPrimary}
                />
              </div>
            </div>
            <Input
              className="mt-[16px] h-[48px] rounded-none border-[#E5E5E5]"
              placeholder="Search Location"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
            />
            <div style={{ color: theme?.customPrimary }} className="mt-[29px] flex gap-[10px]">
              <DetectLocationIcon fillColor={theme?.customPrimary} width="24px" height="24px" />
              <div
                style={{ color: theme?.customPrimary }}
                className="mt-[3px] font-black text-md font-[500]"
                onClick={async () => {
                  await handleDetectLocation({
                    setLocation,
                    setOpen: () => {},
                    setDistanceProps,
                    setAddress,
                    openNotificationWithIcon
                  })
                }}
              >
                Detect current location
              </div>
            </div>
          </div>
          <div className="mt-[21px]"> {status === 'OK' && <>{renderSuggestions()}</>}</div>

          <div>
            {Address?.map((item: any) => {
              return (
                <div
                  className="border:solid border-b-[1px] border-[#E5E5E5] py-[10px] px-[24px]"
                  onClick={() => {
                    setAddress(item)
                    setDistanceProps({ lat: item?.latitude, lng: item?.longitude })
                  }}
                >
                  <div className="text-[#232323],leading-[19px] text-[14px] font-[800]">
                    {item.addressLine1}
                  </div>
                  <div className="text-[rgba(35, 35, 35, 0.5)] mt-[4px] font-book text-md font-medium leading-19">
                    {item.addressLine2}
                  </div>
                </div>
              )
            })}
          </div>
        </Drawer>
      )}
    </Media>
  )
}
