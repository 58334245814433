import { gql } from '@apollo/client'

const GET_APP_SETTINGS = gql`
  query MyQuery($tenantId: String!) {
    getAppSettings(tenantId: $tenantId) {
      themeSettings {
        customPrimary
        custom50
        custom75
        custom10
        gradient
      }
      otherSettings {
        slotInterval
      }
    }
  }
`
export default GET_APP_SETTINGS
