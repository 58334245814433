import React from 'react'
import Image from 'next/image'

export const ImageWithBasePath = (props: {
  isurl?: boolean
  src: string
  height: number
  width: number
  className?: string
  style?: any
  alt?: string
  fill?: boolean
  loading?: any
  layout?: any
}) => {
  const { isurl, ...rest } = props
  const url = isurl ? props.src : `/assets/${props.src}.png`
  return <Image {...rest} src={url} alt=""></Image>
}
