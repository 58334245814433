import moment from 'moment'
import { useState, useEffect } from 'react'

function getStorageValue(key: string, defaultValue?: any) {
  if (typeof window !== 'undefined') {
    const stored: any = localStorage.getItem(key)
    return stored !== 'undefined' && stored != 'null' && stored ? JSON?.parse(stored) : defaultValue
  }

  return null
}

export const useLocalStorage = (key: string, defaultValue?: any) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (key === 'mealDate') {
      const mealDate = getStorageValue(key, defaultValue)
      if (moment(mealDate?.isoString)?.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        setValue({ isoString: '', modifiedDate: '', originalDate: '' })
      } else {
        setValue(getStorageValue(key, defaultValue))
      }
    } else if (key === 'mealType') {
      const mealDate = getStorageValue('mealDate', defaultValue)
      if (moment(mealDate?.isoString)?.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        setValue({ type: '', timeSlot: '', startTime: '', endTime: '' })
      } else {
        setValue(getStorageValue(key, defaultValue))
      }
    } else if (key === 'cartItems') {
      const mealDate = getStorageValue('mealDate', defaultValue)
      if (moment(mealDate?.isoString)?.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        setValue([])
      } else {
        setValue(getStorageValue(key, defaultValue))
      }
    } else {
      setValue(getStorageValue(key, defaultValue))
    }
  }, [])

  useEffect(() => {
    if (key === 'floatingFiltersData') localStorage.setItem(key, JSON.stringify(defaultValue))
    else if (key === 'filterProps') localStorage.setItem(key, JSON.stringify(defaultValue))
    else localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
