import { HomeIcon } from '../svgIcons/homeIcon'
import { NotificationIcon } from '../svgIcons/notificationIcon'
import { ProfileIcon } from '../svgIcons/profileIcon'
import { SubscriptionIcon } from '../svgIcons/subscriptionIcon'
import { DeliveryIcon } from '../svgIcons/deliveryIcon'
import { PickUpIcon } from '../svgIcons/pickUpIcon'
import { ProductType } from '../ts/types/product.types'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const bottomTabs: Array<{
  title: string
  icon: any
  path: string
}> = [
  {
    title: 'Home',
    icon: HomeIcon,
    path: '/'
  },
  {
    title: 'Notifications',
    icon: NotificationIcon,
    path: '/notifications'
  },
  {
    title: 'Profile',
    icon: ProfileIcon,
    path: '/profile'
  }
]

export const floatingFiltersData: Array<ProductType> = [
  {
    title: 'Veg',
    icon: 'vegIcon'
  },
  {
    title: 'Non-Veg',
    icon: 'nonVegIcon'
  },
  {
    title: 'Vegan',
    icon: 'veganIcon'
  },
  {
    title: 'Halal',
    icon: 'halalIcon'
  }
]

export const mealData: Array<{
  title: 'All Day'|'Breakfast' | 'Lunch' | 'Dinner'
  timings: '12:00 AM to 11:59 PM'|'07:00 AM to 11:00 AM' | '12:00 PM to 03:00 PM' | '07:00 PM to 09:00 PM'
}> = [
  {
    title:'All Day',
    timings:'12:00 AM to 11:59 PM'
  },
  {
    title: 'Breakfast',
    timings: '07:00 AM to 11:00 AM'
  },
  {
    title: 'Lunch',
    timings: '12:00 PM to 03:00 PM'
  },
  {
    title: 'Dinner',
    timings: '07:00 PM to 09:00 PM'
  }
]

export const tabsData: {
[key: string]: {
  title: string
  filledIcon: ({ height, width, fillColor, viewBox, opacity }: SvgIconProps) => JSX.Element
  color: string
  width: string
  height: string
}
} = {
 "Delivery": 
 {
    title: 'Deliver to address',
    filledIcon: DeliveryIcon,
    color: '#232323',
    width: '24',
    height: '24'
  },
  "Pickup":
  {
    title: 'Pickup from chef',
    filledIcon: PickUpIcon,
    color: '#232323',
    width: '20',
    height: '20'
  }
}

export const defaultLoginDetails: { isLogin: boolean; userName: string; phoneNumber: string } = {
  isLogin: false,
  userName: '',
  phoneNumber: ''
}
