import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { CartItemProps } from '../ts/types/cartItem.types'
import { PRODUCT } from '../ts/interfaces/product.interface'

export const cartPriceHandler = (cartItems: CartItemProps[]) => {
  let price = 0
  cartItems?.map((item) => {
    price += (item?.foodDetails?.price + item?.extraPrice) * (item?.quantity || 1)
  })
  return price
}

export const emptyFunction = () => {}

export const loadScript = (url: string, callback: Function) => {
  let script: any = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const APIPost = async (endUrl: string, body = {}) => {
  const config = {
    method: 'post',
    url: process.env.NEXT_PUBLIC_LOGIN_API_URL + endUrl,
    data: body
  }
  const response = await axios(config).catch((error) => console.log(error))
  //@ts-ignore
  return response?.data
}

export const productTypeFilterHandler = (productsData: PRODUCT[]) => {
  let filterdObject: any = {}
  let orderedFilter: any = {}
  const filterOrder = ['Starter', 'Main Course', 'Dessert','cakes','pastries','snacks','salads','starter','maincourse','main course','dessert','Cakes','Pastries','Snacks','Salads']
  productsData?.map((item: PRODUCT) => {
    if (Object.keys(filterdObject)?.includes(item?.category?.name)) {
      filterdObject?.[item?.category?.name]?.push(item)
    } else {
      filterdObject[item?.category?.name] = [item]
    }
  })

  filterOrder.map((key: any) => {
    if (filterdObject[key]) {
      orderedFilter[key] = filterdObject[key]
    }
  })
  return orderedFilter
}

export const handleDetectLocation = async ({
  setLocation,
  setOpen,
  setLoadingLocation = () => {},
  setDistanceProps = () => {},
  setAddress,
  openNotificationWithIcon
}: {
  setLocation: React.Dispatch<
    React.SetStateAction<{
      main_text: string
      secondary_text: string
      latitude?: number
      longitude?: number
    }>
  >
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setLoadingLocation?: React.Dispatch<React.SetStateAction<boolean>>
  setDistanceProps: any
  setAddress: React.Dispatch<
    React.SetStateAction<{
      main_text: string
      secondary_text: string
      latitude: number
      longitude: number
    }>
  >
  openNotificationWithIcon?: any
}) => {
  navigator.geolocation.getCurrentPosition(
    async (position) => {
      setLocation({
        main_text: '',
        secondary_text: ''
      })
      setDistanceProps({})
      const { latitude, longitude } = position.coords
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&callback=Function.prototype`
        )
        .then((locationData) => {
          if (locationData?.data?.results?.length) {
            setDistanceProps({ lat: latitude, lng: longitude })
            setLocation({
              main_text: 'Your Location',
              secondary_text: locationData.data.results[0].formatted_address,
              latitude: latitude,
              longitude: longitude
            })
            setAddress({
              main_text: 'Your Location',
              secondary_text: locationData.data.results[0].formatted_address,
              latitude: latitude,
              longitude: longitude
            })
          }
          setOpen(false)
          setLoadingLocation(false)
        })
    },
    async (error) => {
      if (openNotificationWithIcon) {
        openNotificationWithIcon(
          'error',
          error?.message === 'User denied Geolocation'
            ? 'Hey Foodie!! Your geolocation is disabled. Enable it from your browser settings for a deliciously accurate experience.'
            : error?.message
        )
        setLoadingLocation(false)
      }
    }
  )
}

export const getTimeSlots = ({ type, mealSlots, interval, mealDate, timeZone }: any) => {
  const slot = mealSlots?.find((slot: any) => slot.name === type)
  let startTime = moment(slot?.startTime, 'hh:mm A')
  if (
    moment(mealDate)?.format('YYYY-MM-DD') ===
    moment(moment().format()).utcOffset(timeZone).format('YYYY-MM-DD')
  ) {
    while (
      moment(startTime, 'hh:mm A').format('HH:mm') <=
      moment(moment().format()).utcOffset(timeZone).format('HH:mm')
    ) {
      if (moment(startTime, 'hh:mm A').add(30, 'minutes').format('HH:mm') <= moment(moment().format()).utcOffset(timeZone).format('HH:mm')) {
        startTime = moment(startTime, 'hh:mm A').add(30, 'minutes')
      } else {
        startTime = moment(startTime, 'hh:mm A').add(60, 'minutes')
      } 
    }
  }

  let endTime = moment(slot?.endTime, 'hh:mm A').add(1, 'hours')

  let allTimes = []
  let timeSlots = []

  while (startTime < endTime) {
    allTimes.push(startTime.format('hh:mm A'))
    startTime.add(interval, 'minutes')
  }

  for (let i = 0; i < allTimes?.length - 2; i++) {
    if (i + 1) {
      timeSlots.push({ timeSlot: `${allTimes[i]} to ${allTimes[i + 1]}` })
    } else {
      timeSlots.push({ timeSlot: `${allTimes[i]}` })
    }
  }

  return timeSlots
}

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const getFormattedRatingCount = (rating: number) => {
  if (rating < 1000) {
    return rating?.toFixed()
  }

  return rating ? `${(rating / 1000).toFixed(1)}k+` : '4k+'
}

export const reviewFilter = (arr: any, select: any) => {
  let sortedArr = []
  if (select == 1) {
    sortedArr = arr?.slice().sort(function (a: any, b: any): any {
      return +new Date(b?.createdAt) - +new Date(a?.createdAt)
    })
  } else {
    const filterArr = arr.filter((item: any) => {
      if (select == 2) {
        return item.rating <= 2
      } else {
        return item.rating >= select
      }
    })

    sortedArr = filterArr.sort((a: any, b: any) => {
      return b.rating - a.rating
    })
  }

  return sortedArr || []
}

export const getZipCodeUtil = async (lat: number, lon: number) => {
  let pincode = ''
  let city = ''

  const locationData: any = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`
  )

  if (locationData?.data?.results?.length) {
    locationData.data.results[0]?.address_components?.forEach((element: any) => {
      if (element?.types?.includes('postal_code')) {
        pincode = element?.long_name
      }
      if (element?.types?.includes('locality')) city = element?.long_name
      else if (element?.types?.includes('postal_town')) city = element?.long_name
      else if (element?.types?.includes('administrative_area_level_3') && !city)
        city = element?.long_name
    })
  }
  return { pincode, city }
}

export const validateAddAddressForm = (input:  {addressLine1: string, addressLine2: string, pinCode: string, landmark: string, addressType: string, city: string}) => {
  const { addressLine1, addressLine2, addressType, pinCode, city } = input
  let count = 0

  if (addressType.length === 0) count++
  if (addressLine1.length === 0) count++
  if (addressLine2.length === 0) count++
  if (pinCode.length === 0) count++
  if (city.length === 0) count++

  if (count > 1) {
    return { error: true, message: 'Please fill all the mandatory fields' }
  } else {
    if (addressType.length === 0) {
      return { error: true, message: 'Please add Address Nickname to save' }
    } else if (addressType.trim().length === 0 || !/[a-zA-Z1-9]/g.test(addressType)) {
      return { error: true, message: 'Please add a valid Nickname' }
    }
    if (addressLine1.length === 0) {
      return { error: true, message: 'Please add Flat no / House no / Building no to save' }
    }
    if (addressLine2.length === 0) {
      return { error: true, message: 'Please add Address to save' }
    }
    if (pinCode.length === 0) {
      return { error: true, message: 'Please add Pincode/Postcode to save' }
    }
    if (city.length === 0) {
      return { error: true, message: 'Please add City to save' }
    }
  }

  return { error: false, message: '' }
}

export const getFullAddress = (address: {addressLine1: string, addressLine2: string, pinCode: string, landmark?: string}) => {
  const { addressLine1, addressLine2, pinCode, landmark } = address
  if (landmark)
    return [addressLine1?.trim(), addressLine2, landmark?.trim(), pinCode]
      .filter(Boolean)
      .join(', ')
  else return [addressLine1?.trim(), addressLine2, pinCode].filter(Boolean).join(', ')
}

export const isEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0
}

export const getOrderStatus = (status: string) => {
  const orderStatus: any = {
    Pending: 'Pending',
    Confirmed: 'Confirmed',
    Preparing: 'Preparing',
    FoodReady: 'Food Ready',
    Dispatched: 'Dispatched',
    Delivered: 'Delivered',
    PickedUp: 'Picked up',
    CancelledByUser: 'Cancelled by user',
    CancelledByChef: 'Cancelled by chef',
    CancelledByDeliveryPartner: 'Cancelled by delivery partner'
  }

  return orderStatus[status]
}

export const getMealType = (startTime: any, endTime: any, menuSlots: any) => {
  for (let i = 0; i < menuSlots?.length; i++) {
    if (
      moment(startTime).utc().format('HH:mm:ss') >= menuSlots[i]?.startTime?.split('.')[0] &&
      moment(endTime).utc().format('HH:mm:ss') <= menuSlots[i]?.endTime?.split('.')[0]
    ) {
      return menuSlots[i].name
    }
  }
  return null
}

export const getOperationalHours = (mealDate: {
  originalDate: string;
  modifiedDate: string;
  isoString: string;
} | undefined): string => {
  const weekdayObj = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'
  }
  // @ts-ignore
  const choosenDate = weekdayObj?.[moment(mealDate?.isoString).weekday()]
  return choosenDate
}
