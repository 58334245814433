import { useRouter } from 'next/router'
import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { CheckoutIcon } from '../svgIcons/checkoutIcon'
import { DesktopNotificationIcon } from '../svgIcons/desktopNotificationIcon'
import { getFormattedRatingCount } from '../utils/functions'
import { LocationIcon } from '../svgIcons/locationIcon'
import { NewNotificationsIcon } from '../svgIcons/newNotificationsIcon'
import { PrimaryButton } from './PrimaryButton'
import { ProfileDropdown } from './profileDropdown'
import { StarIcon } from '../svgIcons/starIcon'
import { UserIcon } from '../svgIcons/userIcon'

export const DesktopHeader = () => {
  const router = useRouter()
  const {
    loginDetails,
    DesktopHeaderDetails,
    showNotification,
    setShowNotification,
    setShowProfileDropdown,
    showProfileDropdown,
    theme,
    location,
    setSelectedTab,
    tenant,
    notifications,
    setNotDeliverable,
    profileData,
    notificationsLength,
    setNotificationsLength
  } = useContext(AppContext)

  const [newNotification, setNewNotification] = useState(false)
  const [notificationsClicked, setNotificationsClicked] = useState(false)

  useEffect(() => {
    if (notificationsLength < notifications?.length && notificationsClicked) {
      setNotificationsClicked(false)
      setNotificationsLength(notifications?.length)
    }
    if (notificationsLength < notifications?.length) {
      setNewNotification(true)
    }
  }, [notifications?.length, notificationsClicked])

  const iconHandler = () => {
    if (DesktopHeaderDetails?.icon === 'CheckoutIcon') {
      return (
        <CheckoutIcon height="22" width="22" viewBox="0 0 26 26" fillColor={theme?.customPrimary} />
      )
    }
    if (DesktopHeaderDetails?.icon === 'UserIcon') {
      return (
        <UserIcon height="15" width="15" viewBox="0 0 17 17" fillColor={theme?.customPrimary} />
      )
    }
  }

  useEffect(() => {
    setSelectedTab((prev: string[]): string[] => {
      let previousItems = prev?.length ? [...prev] : []
      previousItems.map((tab: string, index: number) => {
        if (tab === 'Notifications') {
          previousItems.splice(index, 1)
        }
      })
      if (showNotification) {
        previousItems.push('Notifications')
      }
      return previousItems
    })
  }, [showNotification])

  return (
    <div className="flex w-screen items-center justify-between bg-[white] py-[14px] shadow-headerShadow md:px-[30px] lg:px-[64px]">
      {DesktopHeaderDetails?.secondaryDesktopHeader ? (
        <div className="flex items-center">
          <div className="-mt-[4px]">
            <CheckoutIcon
              height="22"
              width="22"
              viewBox="0 0 26 26"
              fillColor={theme?.customPrimary}
            />
          </div>
          <div className="ml-[7.42px] font-black text-lg font-[500] leading-[21.86px] text-black-100">
            {DesktopHeaderDetails?.title}
          </div>
        </div>
      ) : DesktopHeaderDetails?.showLocation ? (
        <div
          className="cursor-pointer"
          onClick={async () => {
            setNotDeliverable(false)
            await router.push({ pathname: '/location' })
          }}
        >
          <div className="flex">
            <div className="mt-[1px]">
              <LocationIcon
                width="16"
                height="16"
                fillColor={theme?.customPrimary}
                viewBox="0 0 16 16"
              />
            </div>
            <div className="ml-[4px] w-[180px] overflow-hidden text-ellipsis whitespace-nowrap font-black text-lg font-[500] leading-22">
              {location?.main_text}
            </div>
          </div>
          <div className="w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-book text-sm font-medium leading-22">
            {location?.secondary_text}
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div className="-mt-[4px]">{iconHandler()}</div>
          <div className="ml-[5px] font-black text-lg font-[500] leading-[21.86px] text-black-100">
            {DesktopHeaderDetails?.title}
          </div>
        </div>
      )}
      <div className="flex items-center">
        <Image
          className="cursor-pointer"
          onClick={() => router.push('/')}
          src={tenant?.logo ? tenant?.logo : ''}
          alt={''}
          width={0}
          height={0}
          style={{ width: '47px', height: '47px',borderRadius:'48px' }}
        />
        <div
          className="ml-[8px] mr-[9px] font-black text-[20px] font-[500] leading-[27px]"
          style={{
            color: theme?.customPrimary
          }}
        >
          {tenant?.kitchenName}
          <div className="cursor-pointer font-black text-md font-[500] leading-[19.12px]" style={{color:"black",textAlign:"center"}}> {tenant?.tagLine} </div>
        </div>
        {!DesktopHeaderDetails?.secondaryDesktopHeader && !DesktopHeaderDetails?.profileHeader && (
          <div className="flex h-[27px] w-[145px] items-center justify-center border border-[#F7D61C] bg-[#F7D61C] bg-opacity-10">
            <div className="mr-[5px] font-black text-md font-[500] leading-19 text-[#232323]">
              {tenant?.averageRating?.toFixed(1)?.split('.')?.length > 1
                ? tenant?.averageRating?.toFixed(1)
                : `${tenant?.averageRating?.toFixed(1)}.0`}
            </div>
            <div className="-mt-[3px]">
              <StarIcon height="12" width="12" viewBox="0 0 12 12" fillColor="#F7D61C" />
            </div>
            <div className="px-[4px] font-book text-sm font-medium text-black-50">
              {Number(getFormattedRatingCount(tenant?.totalReviews)) > 1
                ? `${getFormattedRatingCount(tenant?.totalReviews)} Ratings`
                : `${getFormattedRatingCount(tenant?.totalReviews)} Rating`}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        {!DesktopHeaderDetails?.secondaryDesktopHeader && (
          <>
            {DesktopHeaderDetails?.profileHeader && (
              <div
                className="mr-[34.75px] cursor-pointer font-black text-md font-[500] leading-[19.12px]"
                onClick={() => router.push('/')}
              >
                Browse Menu
              </div>
            )}
            {!DesktopHeaderDetails?.aboutHeader && (
              <div
                className="mr-[34.75px] cursor-pointer font-black text-md font-[500] leading-[19.12px]"
                onClick={() => router.push('/about')}
              >
                About
              </div>
            )}
            {loginDetails?.isLogin &&
              (newNotification ? (
                <div
                  onClick={() => {
                    setNewNotification(false)
                    setNotificationsClicked(true)
                    setShowNotification((prev) => !prev)
                  }}
                >
                  <NewNotificationsIcon height="22" width="22" viewBox="0 0 22 22" />
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowNotification((prev) => !prev)
                  }}
                >
                  <DesktopNotificationIcon
                    height="22"
                    width="22"
                    viewBox="0 0 22 22"
                    fillColor="#232323"
                  />
                </div>
              ))}
          </>
        )}
        <div className="ml-[26.75px]">
          {loginDetails?.isLogin ? (
            <div
              className="flex cursor-pointer items-center"
              onClick={() => setShowProfileDropdown((prev) => !prev)}
            >
              <UserIcon
                height="15"
                width="15"
                viewBox="0 0 17 17"
                fillColor={theme?.customPrimary}
              />
              <div className="ml-[6px] mt-[3px] max-w-[200px] truncate font-black text-md font-[500] leading-[19.12px] text-black-100">
                {profileData?.firstName}
              </div>
            </div>
          ) : (
            <PrimaryButton
              title="Login"
              className="px-[8px] py-[7.5px] text-sm"
              onClick={async () => router.push({ pathname: '/login' })}
            />
          )}
        </div>
      </div>
      {showProfileDropdown && <ProfileDropdown />}
    </div>
  )
}
