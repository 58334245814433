import { gql } from '@apollo/client'

const GET_TENANT_DELIVERY_PRICE = gql`
  query MyQuery($tenantId: String!) {
    getTenantDeliveryPrice(tenantId: $tenantId) {
      id
      additionalDistancePrice
      countryCode
      distanceUnit
      maximumDeliveryDistance
      minimumDistance
      minimumDistancePrice
      tenantId
    }
  }
`

export default GET_TENANT_DELIVERY_PRICE
