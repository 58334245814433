import { useQuery, useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import Amplify, { API, graphqlOperation, JS } from 'aws-amplify'
import classNames from 'classnames'
import React, { createContext, useRef, useEffect, useState } from 'react'

import { AppContextType } from '../ts/types/appContext.types'
import { defaultLoginDetails } from '../constants/constants'
import { Header } from './Header'
import { ItemCustomization } from './ItemCustomization'
import { Media } from '../media'
import { TENANT_DETAILS } from '../constants/config'
import { useLocalStorage } from '../hooks/useLocalStorage'
import GET_APP_SETTINGS from '../gql/queries/getAppSettings'
import GET_DELIVERY_PARTNER_PRICE from '../gql/queries/getDeliveryPartnerPrice'
import GET_NOTIFICATIONS from '../gql/queries/getNotifications'
import GET_PROFILE from '../gql/queries/getProfile'
import GET_TENANT_DELIVERY_PRICE from '../gql/queries/getTenantDeliveryPrice'
import GET_TENANT_DETAILS from '../gql/queries/getTenantDetails'
import GET_TENANT_OPERATIONAL_HOURS from '../gql/queries/getTenantOperationalHours'
import NOTIFICATION_LIST from '../gql/queries/getNotificationsSub'
import { PROFILE } from '../ts/interfaces/profile.interface'

export const AppContext = createContext<AppContextType>(null!)

export const Layout = ({
  children,
  themeSettings,
  tenantData,
  dishTypesData
}: {
  children: React.ReactElement
  themeSettings?: any
  tenantData?: any
  dishTypesData?: any
}) => {
  const [cartItems, setCartItems] = useLocalStorage('cartItems', [])
  const [cartTotalPrice, setCartTotalPrice] = useLocalStorage('cartTotalPrice', '')
  const [location, setLocation] = useLocalStorage('location', {
    main_text: 'Set your location',
    secondary_text: ''
  })
  const [mealDate, setMealDate] = useLocalStorage('mealDate', {
    originalDate: '',
    modifiedDate: '',
    isoString: ''
  })
  const [mealType, setMealType] = useLocalStorage('mealType', {
    type: '',
    timeSlot: '',
    startTime: '',
    endTime: '',
    isPickedTimeSlot: false
  })
  const [showCustomisationScreen, setShowCustomisationScreen] = useState(false)
  const [customisationItem, setCustomisationItem] = useLocalStorage('customisationItem', {})
  const [headerDetails, setHeaderDetails] = useLocalStorage('headerDetails', {
    title: 'Back',
    onClick: 'emptyFunction',
    icon: 'back',
    backgroundColor: '#f5f5f6'
  })
  const [notifications, setNotifications] = useState([])
  const [tenant, setTenant] = useLocalStorage(tenantData?.data?.getTenantDetails, {})
  const [timeZone, setTimeZone] = useLocalStorage('timeZone', '')
  const [token, setToken] = useLocalStorage('auth_token', '')
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', '')
  const [accessToken, setAccessToken] = useLocalStorage('access_token', '')
  const [selectedTab, setSelectedTab] = useLocalStorage('selectedTab', ['Home'])
  const [showLocationHeader, setShowLocationHeader] = useState(true)
  const [showHeader, setShowHeader] = useState(true)
  const [checkProduct, setCheckProduct] = useState('')
  const [loginDetails, setLoginDetails] = useLocalStorage('loginDetails', defaultLoginDetails)
  const [DesktopHeaderDetails, setDesktopHeaderDetails] = useLocalStorage('desktopHeaderDetails', {
    secondaryDesktopHeader: false,
    showLocation: true,
    icon: 'CheckoutIcon',
    title: '',
    profileHeader: false,
    aboutHeader: false
  })
  const [openCustomisationModal, setOpenCustomisationModal] = useState(false)
  const [selectedAddress, setSelectedAddress] = useLocalStorage('selectedAddress', '')
  const [filterProps, setFilterProps] = useLocalStorage('filterProps', {
    category: []
  })
  const [filterdProducts, setFilteredProducts] = useLocalStorage('filterdProducts', {})
  const [focusVar, setFocusVar] = useState(false)
  const [listProducts, setListProducts] = useState()
  const [tickHandlerObject, setTickHandlerObject] = useLocalStorage('tickHandlerObject', {})
  const [showNotification, setShowNotification] = useState(false)
  const [showProfileDropdown, setShowProfileDropdown] = useState(false)
  const [previouslyOrdered, setPreviouslyOrdered] = useState([])
  const [address, setAddress] = useState<any>('')
  const [editAddress, setEditAddress] = useState('')
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)
  const [theme, setTheme] = useLocalStorage('theme', {
    gradient: themeSettings?.gradient ?? '',
    customPrimary: themeSettings?.customPrimary ?? '',
    custom75: themeSettings?.custom75 ?? '',
    custom50: themeSettings?.custom50 ?? '',
    custom10: themeSettings?.custom10 ?? ''
  })
  const [openAskCustomiseAgainModal, setopenAskCustomiseAgainModal] = useState(false)
  const [showFile, setShowFile] = useState<boolean>(false)
  const [profileIndex, setprofileIndex] = useLocalStorage('profileIndex', '')
  const [addressModalOpen, setAddressModalOpen] = useState(false)
  const [currentLocation, setCurrentLocation] = useState({ latitude: null, longitude: null });
  const [locationShow, setLocationShow] = useLocalStorage('LocationShow', true)
  const [orderId, setOrderId] = useLocalStorage('orderId', '')
  const [orderDetails, setOrderDetails] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [profileName, setProfileName] = useState<string>('')
  const [profileEmail, setProfileEmail] = useState<string>('')
  const [reCustomiseItem, setReCustomizeItem] = useLocalStorage('reCustomiseItem', {})
  const [otherSettings] = useState()
  const [profileData, setProfileData] = useState()
  const { data: profile } = useQuery(GET_PROFILE, { fetchPolicy: 'cache-and-network' })
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [loggedOut, setLoggedOut] = useState(false)
  useEffect(() => {
    if (profile) {
      setProfileData(profile?.getProfile)
    }
  }, [profile])
  const [yourOrdersItem, setYourOrdersItem] = useState({})
  const [isChatOpened, setIsChatOpened] = useState(true)
  const [isChatOpen, setIsChatOpen] = useState<boolean>(true)
  const [displayNumber, setDisplayNumber] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState('')
  const [distance, setDistance] = useState<number>(0)
  const [deliverable, setNotDeliverable] = useState(false)
  const [mobileNotdeliverable, setMobileNotDeliverable] = useState(false)
  const [previouslyOrderedFilterItems, setPreviouslyOrderedFilterItems] = useState([])
  const [previouslyOrderedItems, setPreviouslyOrderedItems] = useState([])
  const currentData = useRef([])
  const [openFoodNotAvailable, setOpenFoodNotAvailable] = useState(true)
  const [unavailableItem, setUnavailableItem] = useState()
  const [dateChangeConformation, setDateChangeConformation] = useState({ isShow: false, text: '' })
  const [showOtpField, setShowOtpField] = useState(false)
  const [locationHeaderClicked, setLocationHeaderClicked] = useState(false)
  const [isOrderCancelled, setIsOrderCancelled] = useState(true)
  const [tenantOperationalHours, setTenantOperationalHours] = useState()
  const [floatingFiltersData, setFloatingFiltersData] = useLocalStorage(
    'floatingFiltersData',
    dishTypesData?.data?.getDefaultDishType
  )
  const [notificationsLength, setNotificationsLength] = useLocalStorage('notificationsLength', 0)
  const [isAddressOpen, setIsAddressOpen] = useState(true)
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false)
  const [reloaded, setReloaded] = useLocalStorage('reloaded', false)
  const router = useRouter()

  const tenantAuth = useQuery(GET_TENANT_DETAILS, {
    variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID }
  })

  const [getNotification, notificationAuth] = useLazyQuery(GET_NOTIFICATIONS)
  const [country, setCountry] = useState('')
  const [deliveryPartnerPrice, setDeliveryPartnerPrice] = useState({})
  const [tenantDeliveryPrice, setTenantDeliveryPrice] = useState({})

  const { data: deliveryPrice } = useQuery(GET_DELIVERY_PARTNER_PRICE, { skip: !token })
  const { data: tenantPrice } = useQuery(GET_TENANT_DELIVERY_PRICE, {
    variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
    skip: !token
  })

  useEffect(() => {
    if (token) {
      if (tenantAuth?.data) {
        setTenant(tenantAuth?.data?.getTenantDetails)
        if (tenantAuth?.data?.getTenantDetail?.timeZone) {
          setTimeZone(tenantAuth?.data?.getTenantDetail?.timeZone)
        }
      }
      if (notificationAuth?.data) {
        let tempData: any = [...currentData.current, ...notificationAuth?.data.getUserNotifications]
        currentData.current = tempData
        setNotifications(tempData)
        setNotificationsLength(tempData?.length)
      }
      if (deliveryPrice) setDeliveryPartnerPrice(deliveryPrice)
      if (tenantPrice) setTenantDeliveryPrice(tenantPrice)
    } else {
      appSyncRequestSettings()
      getTenantOperationalHours()
      tenantDetails()
      getDeliveryDetails()
    }
  }, [token, tenantAuth.data, notificationAuth.data])

  useEffect(() => {
    if (loggedOut) {
      currentData.current = []
    }
  }, [loggedOut])

  useEffect(() => {
    if (token && profileData) {
      // Notification
      const myAppConfig = {
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
      }
      Amplify.configure(myAppConfig)
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.accessToken`,
        accessToken
      )
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.refreshToken`,
        refreshToken
      )
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.idToken`,
        token
      )
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.LastAuthUser`,
        `${process.env.NEXT_PUBLIC_TENANT_ID}`
      )
      getNotification()
      API.graphql(
        graphqlOperation(NOTIFICATION_LIST, {
          tenantId: process.env.NEXT_PUBLIC_TENANT_ID,
          userId: profileData.sub
        })
      ).subscribe({
        next: (result: any) => {
          const prevNotifications = currentData.current?.map((item) => item?.id)
          if (
            result.value.data.notificationByTenantIdAndUserId?.notificationFor === 'User' &&
            !prevNotifications?.includes(result.value.data.notificationByTenantIdAndUserId?.id)
          ) {
            let tempData: any = [
              ...currentData.current,
              result.value.data.notificationByTenantIdAndUserId
            ]
            currentData.current = tempData
            setNotifications(tempData)
          }
        }
      })
    } else {
      // Remove Notification
      const myAppConfig = {
        aws_appsync_authenticationType: 'AWS_IAM'
      }
      Amplify.configure(myAppConfig)
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.accessToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.refreshToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.${process.env.NEXT_PUBLIC_TENANT_ID}.idToken`
      )
      localStorage.removeItem(
        `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_ID}.LastAuthUser`
      )
    }
  }, [token, profileData])

  const appSettings = (data: any) => {
    const themeData = JSON.parse(TENANT_DETAILS?.theme)
    const { gradient, customPrimary, custom75, custom50, custom10 } = data?.themeSettings
      ? data?.themeSettings
      : themeData
    setTheme({ gradient, customPrimary, custom75, custom50, custom10 })
  }

  const appSyncRequestSettings = async () => {
    const data: any = await API.graphql({
      query: GET_APP_SETTINGS,
      variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
      authMode: 'AWS_IAM'
    })
    appSettings(data.data.getAppSettings[0])
  }

  const getTenantOperationalHours = async () => {
    const data: any = await API.graphql({
      query: GET_TENANT_OPERATIONAL_HOURS,
      variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
      authMode: 'AWS_IAM'
    })
    setTenantOperationalHours(data?.data?.getTenantOperationalHours)
  }

  const tenantDetails = async () => {
    const data: any = await API.graphql({
      query: GET_TENANT_DETAILS,
      variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
      authMode: 'AWS_IAM'
    })
    setTenant(data?.data?.getTenantDetails)
    setTimeZone(data?.data?.getTenantDetails?.timeZone)
  }

  const getDeliveryDetails = async () => {
    const data: any = await API.graphql({
      query: GET_DELIVERY_PARTNER_PRICE,
      authMode: 'AWS_IAM'
    })
    const tenantData: any = await API.graphql({
      query: GET_TENANT_DELIVERY_PRICE,
      variables: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
      authMode: 'AWS_IAM'
    })
    setDeliveryPartnerPrice(data?.data)
    setTenantDeliveryPrice(tenantData?.data)
  }

  return (
    <>
      <AppContext.Provider
        value={{
          cartItems,
          setCartItems,
          cartTotalPrice,
          setCartTotalPrice,
          location,
          setLocation,
          token,
          setToken,
          refreshToken,
          setRefreshToken,
          accessToken,
          setAccessToken,
          deliveryCharge,
          setDeliveryCharge,
          mealDate,
          setMealDate,
          mealType,
          clientSecret,
          setClientSecret,
          setMealType,
          showCustomisationScreen,
          setShowCustomisationScreen,
          customisationItem,
          setCustomisationItem,
          headerDetails,
          setHeaderDetails,
          selectedTab,
          setSelectedTab,
          showLocationHeader,
          setShowLocationHeader,
          showHeader,
          setShowHeader,
          loginDetails,
          setLoginDetails,
          DesktopHeaderDetails,
          setDesktopHeaderDetails,
          openCustomisationModal,
          setOpenCustomisationModal,
          selectedAddress,
          setSelectedAddress,
          filterProps,
          setFilterProps,
          filterdProducts,
          setFilteredProducts,
          focusVar,
          setFocusVar,
          listProducts,
          setListProducts,
          tickHandlerObject,
          setTickHandlerObject,
          showNotification,
          setShowNotification,
          showProfileDropdown,
          setShowProfileDropdown,
          notifications,
          tenant,
          previouslyOrdered,
          setPreviouslyOrdered,
          address,
          setAddress,
          editAddress,
          setEditAddress,
          isReviewModalOpen,
          setIsReviewModalOpen,
          theme,
          setTheme,
          openAskCustomiseAgainModal,
          setopenAskCustomiseAgainModal,
          showFile,
          setShowFile,
          profileIndex,
          setprofileIndex,
          addressModalOpen,
          setAddressModalOpen,
          currentLocation,
          setCurrentLocation,
          notificationsLength,
          setNotificationsLength,
          setNotifications,
          locationShow,
          setLocationShow,
          orderId,
          setOrderId,
          orderDetails,
          setOrderDetails,
          phoneNumber,
          setPhoneNumber,
          profileName,
          setProfileName,
          profileEmail,
          setProfileEmail,
          reCustomiseItem,
          setReCustomizeItem,
          otherSettings,
          profileData,
          setProfileData,
          yourOrdersItem,
          setYourOrdersItem,
          isChatOpened,
          setIsChatOpened,
          isChatOpen,
          setIsChatOpen,
          displayNumber,
          setDisplayNumber,
          distance,
          setDistance,
          deliverable,
          setNotDeliverable,
          mobileNotdeliverable,
          setMobileNotDeliverable,
          previouslyOrderedFilterItems,
          setPreviouslyOrderedFilterItems,
          previouslyOrderedItems,
          setPreviouslyOrderedItems,
          country,
          setCountry,
          openFoodNotAvailable,
          setOpenFoodNotAvailable,
          unavailableItem,
          setUnavailableItem,
          dateChangeConformation,
          setDateChangeConformation,
          showOtpField,
          setShowOtpField,
          locationHeaderClicked,
          setLocationHeaderClicked,
          isOrderCancelled,
          setIsOrderCancelled,
          tenantOperationalHours,
          setTenantOperationalHours,
          deliveryPartnerPrice,
          tenantDeliveryPrice,
          floatingFiltersData,
          isAddressOpen,
          setIsAddressOpen,
          isEditProfileOpen,
          setIsEditProfileOpen,
          setLoggedOut,
          reloaded,
          setReloaded,
          timeZone,
          checkProduct,
          setCheckProduct
        }}
      >
        <Media at="sm">
          {showCustomisationScreen ? (
            <>
              <Header />
              <div className={classNames(showHeader && 'pt-[49px]')}>
                <ItemCustomization />
              </div>
            </>
          ) : (
            <>
              <Header />
              <div className={classNames(showHeader && 'pt-[49px]')}>{children}</div>
            </>
          )}
        </Media>
        <Media greaterThan="sm">
          <Header />
          <div className={classNames(showHeader && 'pt-[75px]')}>{children}</div>
        </Media>
      </AppContext.Provider>
    </>
  )
}
