import { gql } from '@apollo/client'

const GET_DELIVERY_PARTNER_PRICE = gql`
  query MyQuery {
    getDeliveryPartnerPrice {
      id
      additionalDistancePrice
      countryCode
      distanceUnit
      maximumDeliveryDistance
      minimumDistance
      minimumDistancePrice
    }
  }
`

export default GET_DELIVERY_PARTNER_PRICE
